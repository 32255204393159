import React from "react";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
    typography: {
      fontFamily: [
        'Spartan',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
        primary: {
            main: "#001560"
        },
        secondary: {
            main: "#DA4167"
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 700,
            md: 900,
            lg: 1200,
            xl: 1536,
        }
    }
  });

  theme = responsiveFontSizes(theme)

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider theme={theme}>{element}</ThemeProvider>;
};