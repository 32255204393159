exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-agb-jsx": () => import("./../../../src/pages/agb.jsx" /* webpackChunkName: "component---src-pages-agb-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-galerie-jsx": () => import("./../../../src/pages/galerie.jsx" /* webpackChunkName: "component---src-pages-galerie-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-serien-jsx": () => import("./../../../src/pages/serien.jsx" /* webpackChunkName: "component---src-pages-serien-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-pages-ueber-mich-jsx": () => import("./../../../src/pages/ueber-mich.jsx" /* webpackChunkName: "component---src-pages-ueber-mich-jsx" */),
  "component---src-templates-serien-slider-jsx": () => import("./../../../src/templates/serienSlider.jsx" /* webpackChunkName: "component---src-templates-serien-slider-jsx" */),
  "component---src-templates-shop-item-jsx": () => import("./../../../src/templates/shopItem.jsx" /* webpackChunkName: "component---src-templates-shop-item-jsx" */)
}

