module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@iostindex/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mike Wagener - Portfolio","short_name":"Mike Wagener","description":"Hey, ich bin Mike! Auf meiner Webseite siehst du meine letzten Werke.","lang":"de","display":"standalone","icon":"src/images/logo.svg","crossOrigin":"use-credentials","start_url":"/","background_color":"#ebedf2","theme_color":"#001560","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"be3d28a8d8c5974580abb7677def0b27"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/ueber-mich/","/galerie/","/shop/","/serien/"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
